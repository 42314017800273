<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  // No Layout Pages
  import Index from "./views/Index.svelte";
  import About from "./views/About.svelte";
  import Donate from "./views/Donate.svelte";
  import Research from "./views/Research.svelte";
  import Terms from "./views/Terms.svelte";
  import Privacy from "./views/Privacy.svelte";

  export let url = "";
  export let siteUrl = "https://cryptocurrencyresearchinstitute.org";
</script>

<svelte:head>  
  <meta
    property="og:image:secure_url"
    content={`${siteUrl}/assets/img/undraw_open_source_1qxw.png`}
  />
  <link rel="shortcut icon" href={`${siteUrl}/assets/favicon-32x32.png`} />
  <link
    rel="apple-touch-icon"
    sizes="180x180"
    href={`${siteUrl}/assets/apple-touch-icon.png`}
  />
</svelte:head>

<Router url="{url}">
  <!-- no layout pages -->
  <Route path="about" component="{About}" />
  <Route path="donate" component="{Donate}" />
  <Route path="research" component="{Research}" />
  <Route path="terms" component="{Terms}" />
  <Route path="privacy" component="{Privacy}" />
  <Route path="/" component="{Index}" />
</Router>
