<script>
  import { Link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";

  const colab = "/assets/img/undraw_open_source_1qxw.png";

  export let location;
</script>

<IndexNavbar />
<section class="header relative pt-16 items-center flex h-screen max-h-860-px">
  <div class="container mx-auto items-center flex flex-wrap">
    <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
      <div class="pt-32 sm:pt-0">
        <h2 class="font-semibold text-4xl text-gray-700">
          Cryptocurrency Research Institute
        </h2>
        <h3 class="font-semibold text-2xl text-gray-600">
          Clear, Understandable, and Unbiased.
        </h3>
        <p class="mt-4 text-lg leading-relaxed text-gray-600">
          Our mission is to provide clear, understandable, and unbiased information regarding the cryptocurrency ecosystem by conducting and publishing pivotal research focused on establishing trust, instilling confidence, and advancing safe practices for the cryptocurrency using public.
        </p>
        <div class="mt-12">
          <a
            href="donate"
            target="_self"
            class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-500 active:bg-red-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Donate
          </a>
          <a
            href="research#contact"
            class="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gray-800 active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            target="_self"
          >
            Contact us
          </a>
        </div>
      </div>
    </div>
  </div>

  <img
    class="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860-px"
    src="{colab}"
    alt="open source - work together"
  />
</section>

<section class="mt-48 md:mt-40 pb-40 relative bg-gray-700">
  <div
    class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-gray-700 fill-current"
        points="2560 0 2560 100 0 100"
      ></polygon>
    </svg>
  </div>

  <div class="container mx-auto overflow-hidden pb-20 ">
    <div class="flex flex-wrap items-center">
      <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
        <div class="justify-center flex flex-wrap relative">
          <div class="my-4 w-full lg:w-6/12 px-4">
            
              <div
                class="bg-yellow-500 shadow-lg rounded-lg text-center p-8"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="https://github.com/spothq/cryptocurrency-icons/blob/master/128/color/btc.png?raw=true"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Bitcoin (BTC)
                </p>
              </div>

              <div class="bg-blue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="https://github.com/spothq/cryptocurrency-icons/blob/master/128/color/eth.png?raw=true"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Ethereum (ETH)
                </p>
              </div>

              <div
                class="bg-gray-300 shadow-lg rounded-lg text-center p-8 mt-8"
              >
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="https://github.com/spothq/cryptocurrency-icons/blob/master/128/color/ltc.png?raw=true"
                />
                <p class="text-lg text-gray mt-4 font-semibold">
                  Litecoin (LTC)
                </p>
              </div>

          </div>
          <div class="my-4 w-full lg:w-6/12 px-4 lg:mt-16">

              <div class="bg-green-500 shadow-lg rounded-lg text-center p-8">
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="https://github.com/spothq/cryptocurrency-icons/blob/master/128/color/usdt.png?raw=true"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Tether (USDT)
                </p>
              </div>

              <div class="bg-gray-800 shadow-lg rounded-lg text-center p-8 mt-8">
                <img
                  alt="..."
                  class="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                  src="https://github.com/spothq/cryptocurrency-icons/blob/master/128/color/xrp.png?raw=true"
                />
                <p class="text-lg text-white mt-4 font-semibold">
                  Ripple (XRP)
                </p>
              </div>
          </div>
        </div>
      </div>

      <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
        <div
          class="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
        >
          <i class="fas fa-drafting-compass text-xl"></i>
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal text-white">
          Our Research
        </h3>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
          CRI publishes all its reasearch free and publically available for download, you can find it by visiting the link below.
        </p>
        <p class="text-lg font-light leading-relaxed mt-4 mb-4 text-white">
          We're always interested in collaborating, so if you have an idea, feel free to reach out to us.
        </p>
        <a
          href="research"
          target="_self"
          class="font-bold text-white hover:text-white ease-linear transition-all duration-150"
        >
          View all
          <i class="fa fa-angle-double-right ml-1 leading-relaxed"></i>
        </a>
      </div>
    </div>
  </div>

</section>

<section class="pb-16 bg-gray-300 relative pt-32">
  <div
    class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-gray-300 fill-current"
        points="2560 0 2560 100 0 100"
      ></polygon>
    </svg>
  </div>

  <div class="container mx-auto">
    <div
      class="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10"
    >
      <div class="w-full text-center lg:w-8/12">
        <p class="text-4xl text-center">
          <span role="img" aria-label="love">
            &nbsp;
          </span>
        </p>
        <h3 class="font-semibold text-3xl">
          Want to support this cause?
        </h3>
        <p class="text-gray-600 text-lg leading-relaxed mt-4 mb-4">
          The Cryptocurrency Research Institute is a registered nonprofit 501(c)(3) your donations are tax-deductable.
          If you are interested in volunteering your efforts our work is open source and we accept pull requests and contributions.
        </p>
        <div class="sm:block flex flex-col mt-10">
          <a
            href="donate"
            target="_self"
            class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-red-500 active:bg-red-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Donate today
          </a>
          <a
            href="https://github.com/cryptocurrencyresearchinstitute/volunteering"
            target="_blank"
            class="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-gray-800 active:bg-gray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            <i class="fab fa-github text-lg mr-1"></i>
            <span>Volunteer</span>
          </a>
        </div>
        <div class="text-center mt-16"></div>
      </div>
    </div>
  </div>
</section>
<Footer />
