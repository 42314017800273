<script>
  import { link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";

  const team1 = "/assets/img/team-1-800x800.jpg";
  const team2 = "/assets/img/team-2-800x800.jpg";
  const team3 = "/assets/img/team-3-800x800.jpg";
  const team4 = "/assets/img/team-4-470x470.png";
  const leftPhoto = "/assets/img/behnam-norouzi-699H4PEEEe0-unsplash.jpg";
  export let location;
</script>

<div>
  <IndexNavbar />
  <main>
    <section class="relative py-20">
      <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden pt-16 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-white fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    <div class="container mx-auto pt-16 px-4 pb-20">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
            <img
              alt="cryptocurrency app on phone"
              class="max-w-full rounded-lg shadow-lg"
              src="{leftPhoto}"
            />
          </div>
          <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
            <div class="md:pr-12">
              <div
                class="text-red-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-red-300"
              >
                <i class="fas fa-rocket text-xl"></i>
              </div>
              <h3 class="text-3xl font-semibold">Context is key</h3>
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
                Our focus on research can only get us so far, our initiative to bring this information to the public in a digestable way is the icing of the preverbal cake. 
              </p>
              <p class="mt-4 text-lg leading-relaxed text-gray-600">
                We are a collection of volunteer developers, engineers, traders, market makers, product specalists, cryptographic experts, students, researchers, and the general public, we can't thank you all enough for the questions you've asked to help us get on our path. 
              </p>
              <ul class="list-none mt-6">
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3"
                      >
                        <i class="fas fa-fingerprint"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">
                        Unbiased
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3"
                      >
                        <i class="fab fa-html5"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">
                        Free and Fair Access
                      </h4>
                    </div>
                  </div>
                </li>
                <li class="py-2">
                  <div class="flex items-center">
                    <div>
                      <span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-600 bg-red-200 mr-3"
                      >
                        <i class="far fa-paper-plane"></i>
                      </span>
                    </div>
                    <div>
                      <h4 class="text-gray-600">Comprehensive Research</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="relative block bg-gray-900">
      <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-gray-900 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div class="container mx-auto px-4 lg:pt-24 pb-20">
        <div class="flex flex-wrap text-center justify-center">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-semibold text-white mt-20">
              Donate today
            </h2>
            <p class="mt-4 text-lg leading-relaxed text-gray-600">
              Your donation helps pay for the research, development, servers, and bandwidth of our data warehouse used by students, researchers, academics, developers, and companies around the world for pivotal research.
            </p>
            <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
             Your donation is tax deductable.
            </p>
            <p class="mt-4 text-lg leading-relaxed text-gray-600">
              If you provide an email, you will receive an email confirmation for your tax records. Cryptocurrency Research Institute is an independent nonprofit charity and tax-exempt under section 501c3 of the Internal Revenue Code. Our Federal Tax ID Number is 86-1394824.
            </p>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <script src="https://donorbox.org/widget.js" paypalExpress="false">
            </script>
            <iframe
              src="https://donorbox.org/embed/donate-to-cryptocurrency-research-institute"
              name="donorbox"
              allowpaymentrequest=""
              seamless="seamless"
              frameborder="0"
              scrolling="no"
              height="700px"
              width="100%"
              class="mx-auto"
              style="max-width: 500px; min-width: 250px; max-height:none!important"
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</div>
