<script>
  import { link } from "svelte-routing";

  // core components
  import IndexDropdown from "components/Dropdowns/IndexDropdown.svelte";
  const logo = "/assets/img/logo.png";

  let navbarOpen = false;

  function setNavbarOpen() {
    navbarOpen = !navbarOpen;
  }
</script>

<nav
  class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow"
>
  <div
    class="container px-4 mx-auto flex flex-wrap items-center justify-between"
  >
    <div
      class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
    >
      
      <a
        use:link
        class="mt-1 mr-4 py-2"
        href="/"
      >
        <img class="whitespace-no-wrap leading-relaxed inline-block   pr-4 max-w-full h-8" src="{logo}">
        <span class="whitespace-no-wrap uppercase text-gray-800 text-sm font-bold leading-relaxed inline-block text-center align-middle">
        Cryptocurrency Research Institute
        </span>
      </a>
      <button
        class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
        on:click="{setNavbarOpen}"
      >
        <i class="fas fa-bars"></i>
      </button>
    </div>
    <div
      class="lg:flex flex-grow items-center {navbarOpen ? 'block':'hidden'}"
      id="example-navbar-warning"
    >
      <ul class="flex flex-col lg:flex-row list-none mr-auto">
        <li class="flex items-center">
          <a
            class="hover:text-gray-600 text-gray-800 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="research"
          >
            <i class="text-gray-500 far fa-file-alt text-lg leading-lg mr-2" />
            Research
          </a>
        </li>
      </ul>
      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
        <!-- <li class="flex items-center">
          <IndexDropdown />
        </li> -->
        <li class="flex items-center">
          <a
            class="hover:text-gray-600 text-gray-800 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://www.facebook.com/CryptocurrencyResearchInstitute"
            target="_blank"
          >
            <i class="text-gray-500 fab fa-facebook text-lg leading-lg" />
            <span class="lg:hidden inline-block ml-2">Share</span>
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="hover:text-gray-600 text-gray-800 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://twitter.com/CryptoCResearch"
            target="_blank"
          >
            <i class="text-gray-500 fab fa-twitter text-lg leading-lg" />
            <span class="lg:hidden inline-block ml-2">Tweet</span>
          </a>
        </li>

        <li class="flex items-center">
          <a
            class="hover:text-gray-600 text-gray-800 px-3 py-2 flex items-center text-xs uppercase font-bold"
            href="https://github.com/cryptocurrencyresearchinstitute"
            target="_blank"
          >
            <i class="text-gray-500 fab fa-github text-lg leading-lg" />
            <span class="lg:hidden inline-block ml-2">Star</span>
          </a>
        </li>

        <li class="flex items-center">
          <button
            class="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
            type="button"
            href="donate"
            onclick="location.href='donate';"
          >
             Donate
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
