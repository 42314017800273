<script>
  // make dynamic date to be added to footer
  let date = new Date().getFullYear();
</script>

<footer class="relative bg-gray-300 pt-8 pb-6">
  <div
    class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-gray-300 fill-current"
        points="2560 0 2560 100 0 100"
      ></polygon>
    </svg>
  </div>
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap text-center lg:text-left">
      <div class="w-full lg:w-6/12 px-4">
        <h4 class="text-3xl font-semibold">Let's keep in touch!</h4>
        <h5 class="text-lg mt-0 mb-2 text-gray-700">
          Find us on any of these platforms, we respond 1-2 business days.
        </h5>
        <div class="mt-6 lg:mb-0 mb-6">
          <a
            href="https://twitter.com/CryptoCResearch"
            target="_blank">
            <button
              class="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-twitter"></i>
            </button>
          </a>
          <a
            href="https://www.facebook.com/CryptocurrencyResearchInstitute"
            target="_blank">
            <button
              class="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-facebook-square"></i>
            </button>
          </a>
          <a
            href="https://github.com/cryptocurrencyresearchinstitute"
            target="_blank">
            <button
              class="bg-white text-gray-900 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              type="button"
            >
              <i class="fab fa-github"></i>
            </button>
          </a>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="flex flex-wrap items-top mb-6">
          <div class="w-full lg:w-4/12 px-4 ml-auto">
            <span
              class="block uppercase text-gray-600 text-sm font-semibold mb-2"
            >
              Useful Links
            </span>
            <ul class="list-unstyled">
              <li>
                <a
                  class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                  href="about"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                  href="research"
                >
                  Research
                </a>
              </li>
            </ul>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <span
              class="block uppercase text-gray-600 text-sm font-semibold mb-2"
            >
              Other Resources
            </span>
            <ul class="list-unstyled">
              <li>
                <a
                  class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                  href="terms"
                >
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                  href="privacy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  class="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                  href="research#contact"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-6 border-gray-400" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4 mx-auto text-center">
        <div class="text-sm text-gray-600 font-semibold py-1">
          © {date} Cryptocurrency Research Institute, a 501(c)(3) nonprofit organization, EIN 86-1394824. Donations are tax-deductable.
        </div>
      </div>
    </div>
  </div>
</footer>
