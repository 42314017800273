<script>
  import { link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";

  const team1 = "/assets/img/team-1-800x800.jpg";
  const team2 = "/assets/img/team-2-800x800.jpg";
  const team3 = "/assets/img/team-3-800x800.jpg";
  const team4 = "/assets/img/team-4-470x470.png";
  export let location;
</script>

<div>
  <IndexNavbar />
  <main>
    <div
      class="relative pt-32 pb-32 flex content-center items-center justify-center min-h-screen-75"
    >
      <div class="container mx-auto items-center flex flex-wrap">
        <div class="w-6/12 md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div>
            <script src="https://donorbox.org/widget.js" paypalExpress="false">
            </script>
            <iframe
              src="https://donorbox.org/embed/donate-to-cryptocurrency-research-institute"
              name="donorbox"
              class="pt-16 sm:pt-0"
              allowpaymentrequest=""
              seamless="seamless"
              frameborder="0"
              scrolling="no"
              height="700px"
              title="Donation Donorbox"
              width="100%"
              style="max-width: 500px; min-width: 250px; max-height:none!important"
            >
            </iframe>
          </div>
        </div>
        <div class="w-6/12 md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="pt-16 sm:pt-0">
            <h2 class="font-semibold text-4xl text-gray-700">Donate to CRI</h2>
            <p class="mt-4 text-lg leading-relaxed text-gray-600">
              The team at Cryptocurrency Research Institute is committed to the mission to provide clear, understandable, and unbiased information regarding the cryptocurrency ecosystem by conducting and publishing pivotal research focused on establishing trust, instilling confidence, and advancing safe practices for the cryptocurrency using public. Your contribution fuels this cause.
            </p>
            <p class="mt-4 text-lg leading-relaxed text-gray-600">
              Your donation helps pay for the research, development, servers, and bandwidth of our datawarehouse used by students and companies around the world for pivotal research.
            </p>
            <p class="mt-4 text-lg leading-relaxed text-gray-600">
              If you provide an email, you will receive an email confirmation for your tax records. Cryptocurrency Research Institute is an independent nonprofit charity and tax-exempt under section 501c3 of the Internal Revenue Code. Our Federal Tax ID Number is 86-1394824.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</div>
