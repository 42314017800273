<script>
  import { link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";
  import CardTable from "components/Cards/CardTableResearch.svelte";

  export let location;

  function handleOnSubmit(e) {
    e.preventDefault()
    let myForm = document.getElementById('contact_form');
    let myFormMessage = document.getElementById('form_message');
    let formData = new FormData(myForm)
    let encoded = new URLSearchParams(formData).toString()
    let body = encoded
    console.log(body)
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: body
    }).then(() => {
      //alert('Form successfully submitted');
      console.log('Form successfully submitted');
      myFormMessage.innerHTML = "We've received your message, we'll be in touch."
      myForm.classList.add("done");
    }).catch((error) =>
      alert(error))
  }
</script>

<div>
  <IndexNavbar />
  <main>
    <section class="relative py-20">
      <div class="container mx-auto pt-16 px-4">
        <div class="items-center flex flex-wrap">
          <div class="w-full mb-12 px-4">
            <CardTable />
          </div>
        </div>
        <p class="text-sm">
          * All icons used in the table above were created by VINZENCE STUDIO from the Noun Project.
        </p>
      </div>
    </section>

    <section class="pb-20 relative block bg-gray-900">
      <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-gray-900 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div name="contact" id="contact" class="container mx-auto px-4 lg:pt-24 lg:pb-64">
        <div class="flex flex-wrap text-center justify-center">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-semibold text-white">
              Let's build something together
            </h2>
            <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
              Have a research project and looking for data? Interested in finding the root cause or something about the industry? We're here to partner with you to push the industry forward. Reach out below to get in touch.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap mt-12 justify-center">
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
              class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-medal text-xl"></i>
            </div>
            <h6 class="text-xl mt-5 font-semibold text-white">
              Academic research
            </h6>
            <p class="mt-2 mb-4 text-gray-500">
              Let us help with some of the heavy lifting for access to information. We're happy to provide insight and guidance.
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
              class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-poll text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Data access
            </h5>
            <p class="mt-2 mb-4 text-gray-500">
              We've built the data warehouse to ensure unfettered access to data for analysis and use in your research. For free.
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
              class="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
            >
              <i class="fas fa-lightbulb text-xl"></i>
            </div>
            <h5 class="text-xl mt-5 font-semibold text-white">
              Colaborate
            </h5>
            <p class="mt-2 mb-4 text-gray-500">
              CRI is here as a partner to help answer your clients, users, and audiences questions. We provide online and in person learning events.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="relative block py-24 lg:pt-0 bg-gray-900">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
          <div class="w-full lg:w-6/12 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300"
            >
              <div class="flex-auto p-5 lg:p-10">
                <h4 class="text-2xl font-semibold">
                  Get in touch
                </h4>
                <p id="form_message" class="leading-relaxed mt-1 mb-4 text-gray-600">
                  Complete this form and we will get back to you in 48 hours.
                </p>
                <form
                  name="contact_form"
                  id="contact_form"
                  method="POST"
                  data-netlify="true"
                  netlify
                  on:submit|preventDefault={handleOnSubmit}
                >
                <div class="relative w-full mb-3 mt-8">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="name"
                  >
                    Full Name
                  </label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Full Name"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="email"
                  >
                    Email
                  </label>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    for="message"
                  >
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows="4"
                    cols="80"
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                    placeholder="Type a message..."
                  />
                </div>
                <input type="hidden" name="form-name" value="contact_form" />
                <div class="text-center mt-6">
                  <button
                    class="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</div>